import firebase from './firebase';

const TIMEOUTS = {
  CREATE_LABELS_FROM_CSV: 545000,
};

export const FUNCTIONS = {
  batchCreateLabelsFromCsvForSlug: firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-batchCreateLabelsFromCsvForSlug', {
      timeout: TIMEOUTS.CREATE_LABELS_FROM_CSV,
    }),
  updateLabelDataV2: firebase.functions().httpsCallable('httpsOnCallUpdateLabelDataV2'),
  updateLabelState: firebase.functions().httpsCallable('httpsOnCallUpdateLabelState'),
  updateAndValidateDestinationAddress: firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-updateAndValidateDestinationAddress'),
  overrideValidatedDestinationAddressAndGeocode: firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-overrideValidatedAddressAndGeocode'),
  markLabelAsReceived: firebase.functions().httpsCallable('httpsOnCallLabels-markLabelAsReceived'),
  assignLabelsToDsp: firebase.functions().httpsCallable('httpsOnCallLabels-updateDspId'),
  printPdfLabels: firebase
    .functions()
    .httpsCallable('labels-printPdfLabels'),
};

import React, { useEffect, useState } from 'react';
import {
  IDeliveryAttempt,
  ILabelV2,
  ILabelV3,
  ISMSLog,
  ITrackingEvent,
} from '@swyft/swyft-common';
import { Descriptions, Drawer, Space, Tag, Divider, Collapse, Table, message } from 'antd';
import { capitalize, lowerCase, isEmpty } from 'lodash';
import {
  getDeliveryAttempts,
  getSmsLogs,
  getTrackingEvents,
} from '../services/firestore';
import {
  DELIVERY_ATTEMPTS_TABLE_COLUMNS,
  SMS_LOGS_TABLE_COLUMNS,
  TRACKING_EVENTS_TABLE_COLUMNS,
} from '../consts';
import { parseTimestamp } from '../helpers/LabelHelpers';

const { Panel } = Collapse;

type LabelKey = keyof Omit<ILabelV2 | ILabelV3, 'photoUrls'>;

const LABEL_FIELDS: LabelKey[] = [
  'id',
  'trackingNumber',
  'state',
  'merchantId',
  'merchantSlug',
  'businessName',
  'associatedCrossdockId',
  'pickupLocationId',
  'reference',
  'reference2',
  'sortCode',
  'originalShipDate',
  'shipDate',
  'expectedDeliveryDate',
  'completionTime',
  'notes',
  'failureReason',
  'failureNotes',
  'serviceType',
  'received',
  'isDelayed',
  'originalTnt', 
  'virtualTnt',
  'createdAt',
  'updatedAt',
  'deletedAt',
];

interface Props {
  label: ILabelV2 | ILabelV3;
  isVisible: boolean;
  onClose: () => void;
}

export const LabelDetailsDrawer: React.FunctionComponent<Props> = ({
  label,
  isVisible,
  onClose,
}) => {
  const [smsLogs, setSmsLogs] = useState<ISMSLog[]>([]);
  const [trackingEvents, setTrackingEvents] = useState<ITrackingEvent[]>([]);
  const [deliveryAttempts, setDeliveryAttempts] = useState<IDeliveryAttempt[]>([]);

  useEffect(() => {
    if (!isVisible) {
      setSmsLogs([]);
      setTrackingEvents([]);
      setDeliveryAttempts([]);
    }
  }, [isVisible]);

  const renderLabelField = (label: string, value?: any) => {
    let parsedValue = value;

    if (typeof value !== 'object') {
      parsedValue = String(value);
    } else if (value?.seconds) {
      parsedValue = parseTimestamp(value);
    }

    return value && <Descriptions.Item label={label}>{parsedValue}</Descriptions.Item>;
  };

  const renderLabelMap = (valueObject?: object) => {
    return (
      valueObject &&
      Object.entries(valueObject).map(([key, value]) => {
        return renderLabelField(capitalize(lowerCase(key)), value);
      })
    );
  };

  const handleActiveCollapsiblePanelChange = async (keys: string[] | string) => {
    const { merchantId, id } = label;

    if (keys.includes('sms-logs') && isEmpty(smsLogs)) {
      try {
        const smsLogs = await getSmsLogs(merchantId, id);
        setSmsLogs(smsLogs);
      } catch (error) {
        message.error(`Error happened while fetching SMS logs. Error: ${error}`);
        console.error(error);
      }
    }

    if (keys.includes('tracking-events') && isEmpty(trackingEvents)) {
      try {
        const trackingEvents = await getTrackingEvents(merchantId, id);
        setTrackingEvents(trackingEvents);
      } catch (error) {
        message.error(`Error happened while fetching tracking events. Error: ${error}`);
        console.error(error);
      }
    }

    if (keys.includes('delivery-attempts') && isEmpty(deliveryAttempts)) {
      try {
        const deliveryAttempts = await getDeliveryAttempts(merchantId, id);
        setDeliveryAttempts(deliveryAttempts);
      } catch (error) {
        message.error(`Error happened while fetching delivery attempts. Error: ${error}`);
        console.error(error);
      }
    }
  };

  return (
    <Drawer
      title="View Label"
      visible={isVisible}
      placement="right"
      onClose={onClose}
      width="75%"
      destroyOnClose
    >
      {!isEmpty(label) && (
        <>
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Descriptions title="Label Information" bordered column={1}>
              {LABEL_FIELDS.map((key) => renderLabelField(capitalize(lowerCase(key)), label[key]))}

              {label.dims && (
                <Descriptions.Item label="Package dimensions">
                  <Descriptions bordered column={2}>
                    {renderLabelMap(label.dims)}
                  </Descriptions>
                </Descriptions.Item>
              )}

              {renderLabelField(
                'Service add ons',
                label.serviceAddOns?.map((value) => <Tag>{value}</Tag>)
              )}
            </Descriptions>

            {(label as ILabelV3)?.rate  && (
                <Descriptions title="Rate Information" bordered column={1}>
                  {renderLabelField('Currency ', (label as ILabelV3)?.rate.currency)}
                  {renderLabelField('Delivery Price', (label as ILabelV3)?.rate.deliveryPrice)}
                  { (label as ILabelV3)?.rate.options.map( op => {
                    return (
                      <Descriptions title="Options" bordered column={1}>
                        {renderLabelField('Option Name', op.name)}
                        {renderLabelField('Option Price', op.price)}
                      </Descriptions>)
                  })}
                  {renderLabelField('Options Total Price', (label as ILabelV3)?.rate.optionsTotalPrice)}
                  {renderLabelField('Service Type', (label as ILabelV3)?.rate.serviceType)}
                  {renderLabelField('Rate Card Type', (label as ILabelV3)?.rate.rateCardType)}
                  {renderLabelField('TNT', (label as ILabelV3)?.rate.tnt)}
                  {renderLabelField('Total Price', (label as ILabelV3)?.rate.totalPrice)}
                </Descriptions>)
            }

            {(label?.dspManifestInformationArr ||  (label.dspManifestInformation ? [label.dspManifestInformation] : []))?.map( i => {
              return (
                <Descriptions title="Manifest Information" bordered column={1}>
                  {renderLabelField('File Name', i.fileName)}
                  {renderLabelField('Sent at', i.sentAt)}
                  {renderLabelField('DSP ID', i.dspId)}
                </Descriptions>
              )}) 
            }

            <Descriptions title="Destination Information" bordered column={1}>
              {renderLabelField('Destination First name', label.destination.firstName)}
              {renderLabelField('Destination Last name', label.destination.lastName)}
              {renderLabelField('Destination Phone', label.destination.phone)}
              {renderLabelField('Destination Email', label.destination.email)}
              {renderLabelMap(label.destination.address)}
              {renderLabelField('Destination Validated address', label.destination.validatedAddressV2?.label)}
            </Descriptions>

            <Descriptions title="Origin Information" bordered column={1}>
              {renderLabelField('Origin First name', label.origin.firstName)}
              {renderLabelField('Origin Last name', label.origin.lastName)}
              {renderLabelField('Origin Phone', label.origin.phone)}
              {renderLabelField('Origin  Email', label.origin.email)}
              {renderLabelMap(label.origin.address)}
              {renderLabelField('Origin Validated address', label.origin.validatedAddressV2?.label)}
            </Descriptions>
          </Space>
          <Divider />
          <Collapse onChange={handleActiveCollapsiblePanelChange}>
            <Panel header="SMS Logs" key="sms-logs">
              <Table
                size="small"
                scroll={{ x: true }}
                columns={SMS_LOGS_TABLE_COLUMNS}
                dataSource={smsLogs}
                rowKey={(smsLog) => smsLog.id}
              />
            </Panel>
            <Panel header="Tracking Events" key="tracking-events">
              <Table
                size="small"
                scroll={{ x: true }}
                columns={TRACKING_EVENTS_TABLE_COLUMNS}
                dataSource={trackingEvents}
                rowKey={(trackingEvent) => trackingEvent.id as string}
              />
            </Panel>
            <Panel header="Delivery Attempts" key="delivery-attempts">
              <Table
                size="small"
                scroll={{ x: true }}
                columns={DELIVERY_ATTEMPTS_TABLE_COLUMNS}
                dataSource={deliveryAttempts}
                rowKey={(deliveryAttempt) => deliveryAttempt.id}
              />
            </Panel>
          </Collapse>
        </>
      )}
    </Drawer>
  );
};
